<template>
  <div class="created-documents">
    <q-page-container>
      <q-page>
        <div v-if="getDocumentsByProjectId.length">
          <div class="table-created-documents">
            <div class="bottom">
              <TableDocuments
                :documents="getDocumentsByProjectId"
                @download="download"
              />
            </div>
          </div>
        </div>
        <LoadingOrEmpty v-else :loading="getLoadingDocumentsByProjectId" />
      </q-page>
    </q-page-container>
    <a href="" download ref="downloadLink"></a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'
import TableDocuments from '@/components/documents/TableDocuments'

export default {
  name: 'CreatedDocuments',
  components: {
    TableDocuments,
    LoadingOrEmpty
  },
  created() {
    const { projectId } = this.$route.params
    this.fetchDocumentsByProjectId({ projectId })
  },
  computed: {
    ...mapGetters('documents', [
      'getDocumentsByProjectId',
      'getLoadingDocumentsByProjectId',
      'getErrorDocumentsByProjectId'
    ])
  },
  methods: {
    ...mapActions('documents', ['fetchDocumentsByProjectId']),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    download(url) {
      const downloadLink = this.$refs.downloadLink
      downloadLink.href = url
      downloadLink.click()
    }
  },
  watch: {
    getErrorDocumentsByProjectId: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
