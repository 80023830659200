<template>
  <q-table
    :data="documents"
    :columns="columns"
    :hide-bottom="true"
    :pagination.sync="pagination"
    :sort-method="customSort"
  >
    <template v-slot:body="props">
      <q-tr
        :props="props"
        @click="$router.push(`/document/${props.row.reportId}`)"
      >
        <q-td key="reportType" :props="props" class="td-type">
          <span v-if="props.row.reportType === 'DOCX'" class="word-icon"></span>
          <span
            v-if="props.row.reportType === 'EXCEL'"
            class="excel-icon"
          ></span>
        </q-td>
        <q-td key="reportName" :props="props" class="td-document-name">
          {{ props.row.reportName }}
          <q-tooltip v-if="props.row.reportName.length > maxLengthInformation">
            {{ props.row.reportName }}
          </q-tooltip>
        </q-td>
        <q-td key="related" :props="props" class="related-td td-project-name">
          <span
            class="folder-icon"
            :style="{ 'background-color': props.row.projectColor }"
          ></span>
          <span>{{ props.row.projectName }}</span>
        </q-td>
        <q-td key="reportStatus" :props="props" class="td-status">
          <span :class="['status', props.row.reportStatus]">{{
            props.row.reportStatus | translateStatus
          }}</span>
        </q-td>
        <q-td key="updatedOn" :props="props" class="td-update">
          {{ props.row.updatedOn | formattingDate }}
        </q-td>
        <q-td key="actions" :props="props" class="td-actions">
          <q-btn class="btn-eye" type="button"></q-btn>
          <q-btn
            :loading="loading === props.row.reportId"
            class="btn-download"
            type="button"
            @click="e => handleDownloadDocument(e, props.row.reportId, props.row.reportName, props.row.reportType)"
          ></q-btn>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getTime } from 'date-fns'

const maxLengthInfo = 90

export default {
  name: 'TableDocuments',
  props: {
    documents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: '',
      columns: [
        { name: 'reportType', label: 'Type', field: 'type', align: 'left' },
        {
          name: 'reportName',
          label: 'Nom du document',
          field: 'document',
          sortable: true,
          align: 'left'
        },
        {
          name: 'related',
          label: 'Lié au projet',
          field: 'related',
          sortable: true,
          align: 'left'
        },
        {
          name: 'reportStatus',
          label: 'Statut',
          field: 'status',
          sortable: true,
          align: 'left'
        },
        {
          name: 'updatedOn',
          label: 'Dernière màj.',
          field: 'updatedOn',
          sortable: true,
          align: 'left'
        },
        { name: 'actions', label: 'Actions', field: 'actions', align: 'left' }
      ],
      pagination: {
        rowsPerPage: this.documents.length || 0
      },
      maxLengthInformation: maxLengthInfo
    }
  },
  computed: {
    ...mapGetters('documents', ['getErrorDownload'])
  },
  methods: {
    ...mapActions('documents', ['downloadByReportId']),
    async handleDownloadDocument(e, reportId, reportName, reportType) {
      e.stopPropagation()

      this.loading = reportId
      const response = await this.downloadByReportId({ reportId })

      this.loading = ''
      if (!response.s3UrlOriginal) {
        this.showNotify('Une erreur est survenue')
        return
      }

      this.$emit('download', response.s3UrlOriginal, reportName, reportType)
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    customSort(rows, sortBy, descending) {
      const data = [...rows]

      switch (sortBy) {
        case 'reportName':
          return this.handlerSort(data, descending, 'reportName')
        case 'related':
          return this.handlerSort(data, descending, 'projectName')
        case 'reportStatus':
          return this.handlerSort(data, descending, 'reportStatus')
        case 'updatedOn':
          return this.handlerSort(data, descending, 'updatedOn')
        default:
          return data
      }
    },
    handlerSort(data, descending, sortBy) {
      return data.sort((a, b) => {
        const x = descending ? b : a
        const y = descending ? a : b

        if (sortBy === 'updatedOn') {
          const xSortBy = x[sortBy].replace(/\s/, 'T') || new Date()
          const ySortBy = y[sortBy].replace(/\s/, 'T') || new Date()
          const xFormat = getTime(new Date(xSortBy))
          const yFormat = getTime(new Date(ySortBy))

          return xFormat > yFormat ? 1 : xFormat < yFormat ? -1 : 0
        }

        return x[sortBy].toLowerCase() > y[sortBy].toLowerCase()
          ? 1
          : x[sortBy].toLowerCase() < y[sortBy].toLowerCase()
          ? -1
          : 0
      })
    }
  },
  watch: {
    getErrorDownload: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  },
  filters: {
    translateStatus: function(status) {
      if (status == 'READY') {
        return 'En cours'
      }
      else {
        return 'Terminé'
      }
    }
  }
}
</script>
